import { useRef } from 'react';
import * as _ from 'lodash';
import { FTLPullQuoteRatesRes } from '@/services/apis';
import { useFTLContext } from '@/services/contexts';
import { useBoolean, useMemoizedFn, useMount } from 'ahooks';
import { apiHooks } from '@/hooks/apis';

export const useHandlePullQuote = () => {
	const ftlContext = useFTLContext();

	const pullTimer = useRef<NodeJS.Timeout | null>(null);

	const [
		quoteRateCompleted,
		{ setTrue: setQuoteRateCompleted, setFalse: unsetQuoteRateCompleted },
	] = useBoolean(false);

	const { runAsync: pullQuoteRates, cancel: stopPullQuoteRates } = apiHooks.useFTLPullQuoteRates({
		pollingInterval: 3000,
		onSuccess(data: { data: FTLPullQuoteRatesRes }) {
			const [current, total] = data.data.quoteStatus.split('/');
			if (current === total) {
				const minRate = _.minBy(data.data.data, 'rate');
				ftlContext.rate = minRate;
				stopPullQuoteRates();
				setQuoteRateCompleted();
			}
		},
	});

	const { runAsync: updateFTLQuote } = apiHooks.useUpdateFTLQuote();

	/**
	 * 轮询 rate
	 */
	const handlePullQuote = useMemoizedFn(() => {
		unsetQuoteRateCompleted();
		stopPullQuoteRates();

		ftlContext.rate = undefined;

		void pullQuoteRates({ path: { quote_id: ftlContext.quote?.id } });

		if (pullTimer.current) {
			clearTimeout(pullTimer.current);
		}
		pullTimer.current = setTimeout(() => {
			if (!quoteRateCompleted) {
				stopPullQuoteRates();
				setQuoteRateCompleted();
			}
		}, 1000 * 60);
	});

	/**
	 * 更新 quote
	 */
	const handleUpdateFTLQuote = useMemoizedFn(async () => {
		const res = await updateFTLQuote({
			path: {
				id: ftlContext.quote.id,
			},
			data: {
				pickup_zipcode: ftlContext.pickupAddress.zipcode,
				destination_zipcode: ftlContext.destinationAddress.zipcode,
				pickup_city_id: ftlContext.pickupAddress.city.id,
				destination_city_id: ftlContext.destinationAddress.city.id,
				pickup_date: ftlContext.pickupDate?.format('YYYY-MM-DD') ?? '',

				// equipment_type: ftlContext.selectedEquipment.value,
				equipment_type: 'DRV',
			},
		});
		ftlContext.quote = res.data.data;

		handlePullQuote();
	});

	useMount(() => handlePullQuote());

	return {
		quoteRateCompleted,
		handleUpdateFTLQuote,
	};
};
