import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetItemsFreightClassProps, GetItemsFreightClassRes } from './interface';

export const getItemsFreightClass = async (props: GetItemsFreightClassProps) => {
	return await axios.post<GetItemsFreightClassRes>(
		'/tool/freight/class/items/calculate',
		props.items,
		{
			headers: {
				Authorization: accessTokenManager.getAccessToken(),
			},
		},
	);
};
