import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CenterSpin from '@/components/center-spin';
import axiosInstance from '@/services/apis/axios';
import accessTokenManager from '@/services/contexts/user/access-token-manager';
import { unauthedNavigate } from '@/utils/unauthed-navigate';

export default function V3VerifyAuthToken() {
	const navigate = useNavigate();
	const { token } = useParams();

	useEffect(() => {
		const verifyToken = async () => {
			try {
				if (!token) {
					throw new Error('No auth token provided');
				}

				const resp = await axiosInstance.get('/retrieveToken', {
					params: { __auth_token__: token },
				});

				accessTokenManager.setAccessToken(resp.data.access_token);
				navigate('/');
			} catch (error) {
				unauthedNavigate(true);
			}
		};

		void verifyToken();
	}, [navigate, token]);

	return <CenterSpin size='large' />;
}
