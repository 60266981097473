import { useTranslation } from 'react-i18next';
import { Button, Divider, Tooltip } from 'antd';
import { QuoteBusinessStatus } from '@/interfaces/quote.interface';
import { useFTLContext } from '@/services/contexts';
import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import Balance from '@/components/balance/balance';
import { Gap } from '@/components/gap';
import { Item } from '@/pages/ltl-order/item';
import { formatMMDDYYYYDate } from '@/utils/format-MMDDYYYY-date';
import { ShipmentInfoEditButton, ShipmentInfoMap, ShipmentInfoRoute } from '../shipment-info';
import { ShipmentInfoPickupDate } from '../shipment-info/shipment-info-pickup-date';
import { ServiceDetailModal } from './service-detail-modal';
import { useNavigate } from 'react-router-dom';

export const SideMap = observer(
	({
		disableButton,
		loading,
		createLoading,
		onFinish,
	}: {
		disableButton: boolean;
		loading: boolean;
		createLoading: boolean;
		onFinish: () => void;
	}) => {
		const { t } = useTranslation();
		const navigate = useNavigate();

		const ftlContext = useFTLContext();

		const rate = ftlContext.rate;

		return (
			<div className='w-full h-full'>
				<div className='w-full h-[180px]'>
					<ShipmentInfoMap />
				</div>

				<div className='box-border p-[24px] w-full h-fit'>
					<ShipmentInfoRoute />
					<Gap height='4px' />
					<ShipmentInfoPickupDate />
					<Gap height='8px' />
					{ftlContext.quote.business_status === QuoteBusinessStatus.SUBMITED &&
						!!ftlContext.rate?.is_est_pickup_date_invalid && <ShipmentInfoEditButton />}
					<Gap height='16px' />

					<div className='flex'>
						<div className='w-1 grow'>
							<Item
								title={t(`Estimated Delivery`)}
								content={
									!loading ? (
										<div className='flex items-center gap-[4px]'>
											<div>{formatMMDDYYYYDate(rate?.estimated_delivery_date)}</div>
											<Tooltip
												title={t(
													`The estimated delivery date falls on a weekend or holiday, it is recommended to adjust the pick-up date to avoid delayed delivery or additional charges.`,
												)}
											>
												<InfoCircleOutlined />
											</Tooltip>
										</div>
									) : (
										<LoadingOutlined />
									)
								}
							/>
						</div>
						<div className='w-1 grow'>
							<Item
								title={t(`Transit Time`)}
								content={!loading ? `${rate?.transit_days} ${t('days')}` : <LoadingOutlined />}
							/>
						</div>

						<div className='w-1 grow'>
							<Item
								title={t(`Equipment`)}
								content={!loading ? t(`53ft Dry Van`) : <LoadingOutlined />}
							/>
						</div>
					</div>

					<Divider />

					<div className='flex h3-b text-black'>
						<div className='w-1 grow'>
							<div>{t('Total')}</div>
						</div>
						<div className='w-1 grow'>
							<div>
								{!loading ? (
									rate?.rate ? (
										`US $ ${Number(rate?.rate) + ftlContext.premium}`
									) : (
										''
									)
								) : (
									<LoadingOutlined />
								)}
							</div>
						</div>
					</div>

					<Gap height='12px' />

					<div className='flex'>
						<div className='w-1 grow'>
							<Item
								title={t(`Base price`)}
								content={!loading ? `US $${rate?.rate ?? ''}` : <LoadingOutlined />}
							/>
						</div>
						{/* <div className='w-1 grow'>
							<Item
								title={t(`Cargo Value`)}
								content={
									!loading ? (
										`$${ftlContext.shipmentFormValues.cargo_value ?? '--'}`
									) : (
										<LoadingOutlined />
									)
								}
							/>
						</div> */}
						{/* <div className='w-1 grow'>
							<Item
								title={t(`Premium`)}
								content={!loading ? `US $${ftlContext.premium}` : <LoadingOutlined />}
							/>
						</div> */}
					</div>
					<Gap height='12px' />

					<Balance>
						{(balance: number | string) => <Item title={t(`Balance`)} content={`US $${balance}`} />}
					</Balance>

					<ServiceDetailModal />

					<Gap height='24px' />

					<Button
						type='primary'
						block
						disabled={disableButton}
						loading={createLoading}
						className='h-[48px]'
						onClick={() => {
							onFinish();
						}}
					>
						<span className='text-[18px] font-[600] text-white'>{t(`Place Order`)}</span>
					</Button>

					<Gap height='12px' />

					<Button
						type='default'
						block
						className='h-[48px] border-[#1890ff] hover:border-[#40a9ff]'
						onClick={() => {
							navigate(-1);
						}}
					>
						<span className='text-[18px] font-[600] text-[#1890ff] hover:text-[#40a9ff]'>
							{t(`Back`)}
						</span>
					</Button>
				</div>
			</div>
		);
	},
);
