import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';
import { ConfigProvider, Layout } from 'antd';
import enUS from 'antd/locale/en_US';
import zhCN from 'antd/locale/zh_CN';
import { useMount } from 'ahooks';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import CenterSpin from './components/center-spin';
import Empty from '@/components/empty';
import useNavigateToIndex from './hooks/use-navigate-to-index';
import { billingCodeNameTranslateKey } from './utils/billing-code-translate';
import { unauthedNavigate } from './utils/unauthed-navigate';
import './App.less';
import { LANGUAGE_EN_US, LANGUAGE_ZH_CN, updateLanguagePack } from './i18n';
import { BillingCode } from './interfaces/billing-code.interface';
import { Sider } from './layout/sider';
import { useBillingCodeContext, useGlobalContext } from './services/contexts';
import { book } from './services/contexts/book/book.context';
import accessTokenManager from './services/contexts/user/access-token-manager';
import MaintenanceAnnouncement from './components/maintenance-announcement/index.tsx';

const { Content } = Layout;

const App = observer(() => {
	const { navigateToIndex } = useNavigateToIndex();

	const { i18n } = useTranslation();

	const location = useLocation();

	const { userContext, cacheContext, screenContext } = useGlobalContext();

	const billingCodeContext = useBillingCodeContext();

	const [isFetching, setIsFetching] = useState(true);

	const currentPath = location.pathname;

	if (currentPath === '/') {
		navigateToIndex();
	}

	const updateLangauageWithBillingCodes = () =>
		billingCodeContext
			.fetchBillingCodes()
			.then((res: BillingCode[]) => {
				res.map((billingCode) => {
					updateLanguagePack(
						LANGUAGE_EN_US,
						billingCodeNameTranslateKey(billingCode.name),
						billingCode.name,
					);
					updateLanguagePack(
						LANGUAGE_EN_US,
						billingCodeNameTranslateKey(billingCode.code),
						billingCode.name,
					);
					updateLanguagePack(
						LANGUAGE_ZH_CN,
						billingCodeNameTranslateKey(billingCode.name),
						billingCode.cn_name,
					);
					updateLanguagePack(
						LANGUAGE_ZH_CN,
						billingCodeNameTranslateKey(billingCode.code),
						billingCode.cn_name,
					);
				});
			})
			.catch((error) => {
				console.error('Failed to fetch billing codes: ', error);
			});

	const init = async () => {
		// 判断 access token 是否存在
		if (!accessTokenManager.isAccessTokenValid()) {
			throw new Error('unauthed');
		}

		// 判断 access token 是否合法
		await userContext.fetchUserInfoByAccessToken();
		await updateLangauageWithBillingCodes();
	};

	const initEntry = async () => {
		try {
			setIsFetching(true);
			await init();
			await cacheContext.init();
			setIsFetching(false);
		} catch (error) {
			unauthedNavigate();
		}
	};

	useEffect(() => {
		void initEntry();
	}, []);

	// 离开 book 页面时清除原数据
	useEffect(() => {
		if (location.pathname !== '/search-rate/book') {
			book.clear();
		}
	}, [location]);

	useMount(() => {
		if (i18n.language === 'zh-CN') {
			dayjs.locale('zh-cn');
		} else {
			dayjs.locale('en');
		}
	});

	if (isFetching || cacheContext.fetching) {
		return <CenterSpin size='large' />;
	}

	return (
		<ConfigProvider
			locale={i18n.language === 'zh-CN' ? zhCN : enUS}
			theme={{
				components: {
					Button: {
						colorPrimary: '#007bff',
					},
				},
			}}
			renderEmpty={() => <Empty />}
		>
			<MaintenanceAnnouncement beijingDateTime='2024/12/1 2PM' duration={{ hours: 2 }} />
			<Layout className={clsx(screenContext.isScreenShot ? 'h-max' : 'h-screen')}>
				<Sider />
				<Content id='main'>
					<Outlet />
				</Content>
			</Layout>
		</ConfigProvider>
	);
});

export default App;
