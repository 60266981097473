/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Button,
	Col,
	Form,
	Input,
	InputNumber,
	message,
	Modal,
	Row,
	Select,
	Switch,
	UploadFile,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { Company } from '@/interfaces/company.interface';
import { createCompany } from '@/services/apis/create-agent-company';
import { updateCompany } from '@/services/apis/update-agent-company';
import { userContext } from '@/services/contexts/user';
import { get } from 'lodash';
import { observer } from 'mobx-react-lite';
import Documents from '@/components/documents';
import { DocumentsLocal } from '@/components/documents-local';
import { ContactList } from './ContactList';
import { advancedTermsRule, TIER_MAP } from './data';
import { CompanyModalProps } from './type';

const Index = () => {
	const [open, setOpen] = useState(false);

	const [company, setCompany] = useState<any>();

	const tierRate = userContext.getUserInfo()?.company?.tier_rate ?? 0;
	const tlMarginPercent = userContext.getUserInfo()?.company?.tl_margin_percent ?? 5;
	const ftlMarginPercent = userContext.getUserInfo()?.company?.ftl_margin_percent ?? 5;

	const openCompanyModal = useCallback((company?: Company) => {
		setOpen(true);
		setCompany(company);
	}, []);

	const closeCompanyModal = () => {
		setOpen(false);
		setCompany(undefined);
	};

	const CompanyModal = observer(({ onAfterSave, ...props }: CompanyModalProps) => {
		const { t } = useTranslation();

		const [confirmLoading, setConfirmLoading] = useState(false);

		const [form] = Form.useForm<Company>();

		const [editCompany, setEditCompany] = useState<Company | null>(company || null);

		const isCreating = !editCompany;

		const handleFinish = async (values: any) => {
			values = { ...values };
			console.log(values);
			setConfirmLoading(true);

			const action = isCreating ? 'Create' : 'Update';

			try {
				if (isCreating) {
					const formData = new FormData();
					Object.keys(values).forEach((key) => {
						if (key === 'files' || key === 'contacts') {
							values[key]?.forEach((file: UploadFile) => {
								formData.append(`${key}[]`, file.originFileObj);
							});
						}
						if (key === 'has_contract') {
							formData.append(key, values[key] ? '1' : '0');
						} else {
							if (values[key]) {
								formData.append(key, values[key]);
							}
						}
					});
					// convert boolean to string

					await createCompany({ data: formData });
				} else {
					await updateCompany({
						data: values,
					});
				}

				message.success(t(`${action} company success`));
				onAfterSave && onAfterSave();
				closeCompanyModal();
			} catch (error) {
				message.error(t(`${action} company error: ${get(error, 'response.data.message')}`));
			} finally {
				setConfirmLoading(false);
			}
		};

		useEffect(() => {
			if (!editCompany) {
				return;
			}
			form.setFieldsValue({ ...editCompany });
		}, [editCompany]);

		return (
			<Modal
				{...props}
				open={open}
				title={isCreating ? t('Add a New Company') : t('Edit Company')}
				width={'80%'}
				footer={null}
				onCancel={closeCompanyModal}
			>
				<Form
					form={form}
					onFinish={(values) => {
						void handleFinish(values);
					}}
					layout='vertical'
					initialValues={
						editCompany
							? {
									...editCompany,
								}
							: {
									tl_margin_percent: tlMarginPercent,
									ftl_margin_percent: ftlMarginPercent,
								}
					}
				>
					<Form.Item name='id' noStyle>
						<Input hidden />
					</Form.Item>
					<Row>
						<Col span={24}>
							<Form.Item
								label={t('Code')}
								name='code'
								rules={[{ required: true, message: 'Please Input Code' }]}
							>
								<Input id='user_form_code' placeholder='Enter code' />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
								label={t('English Name')}
								name='name'
								rules={[{ required: true, message: 'Please Input Name' }]}
							>
								<Input id='user_form_name' placeholder='Enter name' />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
								label={t('Legal Name')}
								name='legal_name'
								rules={[{ required: true, message: 'Please Input Legal Name' }]}
							>
								<Input id='user_form_legal_name' placeholder='Enter Legal name' />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
								label={t('Terms')}
								name='terms'
								extra='Please input terms in the format of 15 or 30 or 25+28 or 28+5 ...'
								rules={[{ required: true, message: 'Please Input terms' }, advancedTermsRule]}
							>
								<Input placeholder='Enter terms' />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
								label={t('Address')}
								name='address'
								rules={[{ required: true, message: 'Please Input Address' }]}
							>
								<TextArea rows={6} placeholder='Enter company' />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
								label={t('Billing Address')}
								name='billing_address'
								rules={[
									{ required: true, message: 'Please Input Billing Address' },
									// add validator to check if billing address includes input name
									{
										validator: (rule, value: string) => {
											const name = form.getFieldValue('name') as string;
											if (typeof value === 'string' && value.includes(name)) {
												return Promise.resolve();
											}
											return Promise.reject(new Error('Billing Address must include English Name'));
										},
										message: 'Billing Address must include English Name',
									},
								]}
							>
								<TextArea rows={6} placeholder='Enter company' />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item label={t('Has Contract')} name='has_contract'>
								<Switch />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Row gutter={8}>
								<Col span={8}>
									<Form.Item
										label={t('LTL Margin Percent')}
										name='tl_margin_percent'
										extra='Please input truckload margin percent.'
										rules={[
											{
												required: true,
												type: 'number',
												min: tlMarginPercent,
												message: `Please Input TL Margin Percent is not least than ${tlMarginPercent}`,
											},
										]}
									>
										<InputNumber addonAfter={'%'} placeholder='Enter truckload margin percent' />
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item
										label={t('FTL Margin Percent')}
										name='ftl_margin_percent'
										extra='Please input truckload margin percent.'
										rules={[
											{
												required: true,
												type: 'number',
												min: ftlMarginPercent,
												message: `Please Input FTL Margin Percent is not least than ${ftlMarginPercent}`,
											},
										]}
									>
										<InputNumber addonAfter={'%'} placeholder='Enter truckload margin percent' />
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item name='tier_rate' label={t('Dray Tier Rate')}>
										<Select placeholder='Select tier' allowClear>
											{Object.keys(TIER_MAP)
												.filter((key) => +key >= tierRate)
												.map((key: any) => (
													<Select.Option key={key} value={key}>
														{TIER_MAP[key as unknown as keyof typeof TIER_MAP]}
													</Select.Option>
												))}
										</Select>
									</Form.Item>
								</Col>
							</Row>
						</Col>

						<Col span={24}>
							<Form.Item shouldUpdate>
								{({ getFieldValue, setFieldValue }) =>
									getFieldValue('id') ? (
										<Documents target='company' targetId={getFieldValue('id')} />
									) : (
										<>
											<Form.Item name={'files'} hidden noStyle>
												<Input hidden />
											</Form.Item>
											<Form.Item noStyle shouldUpdate>
												<DocumentsLocal
													files={getFieldValue('files')}
													onFilesChange={(files) => {
														setFieldValue('files', files);
													}}
												/>
											</Form.Item>
										</>
									)
								}
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Form.Item noStyle shouldUpdate>
								{({ getFieldValue, setFieldValue }) => (
									<Form.Item
										name='contacts'
										key='form_contacts'
										labelCol={{ span: 24 }}
										wrapperCol={{ span: 24 }}
									>
										<ContactList
											data={getFieldValue('contacts') || []}
											updateData={(list) => setFieldValue('contacts', list)}
										/>
									</Form.Item>
								)}
							</Form.Item>
						</Col>
					</Row>
					<Form.Item>
						<Button type='primary' htmlType='submit' loading={confirmLoading}>
							{isCreating ? t('Create') : t('Update')}
						</Button>
					</Form.Item>
				</Form>
			</Modal>
		);
	});

	return {
		CompanyModal,
		openCompanyModal,
		closeCompanyModal,
	};
};

export default Index;
