import { useGlobalContext } from '@/services/contexts';
import { useMemoizedFn } from 'ahooks';
import { Dayjs } from 'dayjs';

export const useIsPickupDateDisabled = (config: { whiteListType: 'ltl' | 'ftl' }) => {
	const { cacheContext } = useGlobalContext();

	const pickupDateFromNow =
		config.whiteListType === 'ltl' ? cacheContext.ltlPickupDateMap : cacheContext.ftlPickupDateMap;

	/**
	 * 当 white list 或 holidays 未加载完，回直接返回
	 */
	const isPickupDateDisabled = useMemoizedFn((current: Dayjs) => {
		const currentFormat = current.format('YYYY-MM-DD');

		if (pickupDateFromNow[currentFormat] === undefined) {
			return true;
		}

		return !pickupDateFromNow[currentFormat];
	});

	return {
		isPickupDateDisabled,
	};
};
