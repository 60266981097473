import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Pagination, Table, TableProps } from 'antd';
import { FTLBusinessStatusMap, FTLShipment } from '@/interfaces/ftl-shipment.interface';
import { businessStatusMap } from '@/interfaces/shipment.interface';
import '@/styles/no-cell-border-table.less';
import { LoadingOutlined } from '@ant-design/icons';
import { useMemoizedFn, useMount, useSetState } from 'ahooks';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { Gap } from '@/components/gap';
import { apiHooks } from '@/hooks/apis';
import { LTLFTLFilter } from '../ltl-orders/ltl-ftl-filter';
import { StatusLabel } from './status-label';
import useFtlOrdersParams, { FTLOrdersQuery } from './use-ftl-orders-params';

const FTLOrders = observer(({ mode }: { mode?: string }) => {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const { parsedSearchParams, changeSearchParams } = useFtlOrdersParams();

	const [queriesWithoutPagination, setQueriesWithoutPagination] = useSetState<
		Pick<FTLOrdersQuery, 'customer_status' | 'last_days' | 'query' | 'sort_by' | 'sort_value'>
	>({
		customer_status: parsedSearchParams.customer_status ?? [],
		last_days: parsedSearchParams.last_days ?? '30',
		query: parsedSearchParams.query ?? '',
		sort_by: parsedSearchParams.sort_by ?? '',
		sort_value: parsedSearchParams.sort_value ?? 'desc',
	});

	const {
		runAsync: getFTLShipmentList,
		data: FTLShipmentList,
		loading: FTLShipmentListLoading,
		pagination,
	} = mode == 'agent'
		? apiHooks.useGetAgentShipmentList(
				{
					refreshDeps: [queriesWithoutPagination],
					refreshDepsAction: () => {
						void getFTLShipmentList(
							{ current: 1, pageSize },
							{
								params: {
									...queriesWithoutPagination,
									customer_status: (queriesWithoutPagination.customer_status ?? [])
										.flatMap((i) => (i === '40' ? ['40', '60'] : [i]))
										.join(','),
									last_days:
										queriesWithoutPagination.last_days == 'all'
											? ''
											: queriesWithoutPagination.last_days,
								},
							},
						);
					},
				},
				'ftl',
			)
		: apiHooks.useGetFTLShipmentList({
				refreshDeps: [queriesWithoutPagination],
				refreshDepsAction: () => {
					void getFTLShipmentList(
						{ current: 1, pageSize },
						{
							params: {
								...queriesWithoutPagination,
								customer_status: (queriesWithoutPagination.customer_status ?? [])
									.flatMap((i) => (i === '40' ? ['40', '60'] : [i]))
									.join(','),
								last_days:
									queriesWithoutPagination.last_days == 'all'
										? ''
										: queriesWithoutPagination.last_days,
							},
						},
					);
				},
			});

	const { current, total, pageSize, onChange: changePagination } = pagination;

	const columns: TableProps<FTLShipment>['columns'] = [
		{
			title: t(`Order #`),
			dataIndex: 'uid',
			width: 120,
			fixed: 'left',
		},
		{
			title: t('User'),
			dataIndex: ['user', 'name'],
			width: 120,
		},
		{
			title: t(`Reference #`),
			dataIndex: 'customer_reference_number',
			width: 120,
		},
		{
			title: t(`Status`),
			dataIndex: 'customer_status',
			width: 120,
			render: (value, record) => (
				<StatusLabel status={value} text={businessStatusMap[value]} reason={record.reject_reason} />
			),
		},
		{
			title: t(`Pickup Time`),
			dataIndex: 'actual_pickup_date',
			width: 140,
			render: (_, record) => (
				<div>
					<div>
						{!!record.actual_pickup_date || !!record.estimated_pickup_date
							? dayjs(record.actual_pickup_date ?? record.estimated_pickup_date).format(
									'YYYY-MM-DD',
								)
							: ''}
					</div>
					{/* <div className='text-grey-01'>
							<TimeWindowRender
								time_type={record.pickup_time_type}
								open_time={record.pickup_open_time}
								close_time={record.pickup_close_time}
								specific_time={record.pickup_specific_time}
							/>
						</div> */}
				</div>
			),
		},
		{
			title: t(`Pickup City`),
			dataIndex: 'pickup_zipcode',
			width: 200,
			render: (_, record) => (
				<div>
					<div>{record.pickup_city.name}</div>
					<div className='text-grey-01'>{`${record.pickup_city.state} - ${record.pickup_zipcode}`}</div>
				</div>
			),
		},
		{
			title: t(`Delivery Time`),
			dataIndex: 'actual_delivery_date',
			width: 140,
			render: (_, record) => (
				<div>
					<div>
						{!!record.actual_delivery_date || !!record.estimated_delivery_date
							? dayjs(record.actual_delivery_date ?? record.estimated_delivery_date).format(
									'YYYY-MM-DD',
								)
							: ''}
					</div>
					{/* <div className='text-grey-01'>
							<TimeWindowRender
								time_type={record.destination_time_type}
								open_time={record.destination_open_time}
								close_time={record.destination_close_time}
								specific_time={record.destination_specific_time}
							/>
						</div> */}
				</div>
			),
		},
		{
			title: t(`Destination`),
			dataIndex: 'destination_zipcode',
			width: 200,
			render: (_, record) => (
				<div>
					<div>{record.destination_city.name}</div>
					<div className='text-grey-01'>{`${record.destination_city.state} - ${record.destination_zipcode}`}</div>
				</div>
			),
		},
		{
			title: t('Base Price'),
			dataIndex: 'sell_rate',
			width: 120,
			render: (value) => `$${value}`,
		},
		{
			title: t(`Actions`),
			key: 'actions',
			width: 120,
			fixed: 'right',
			render: (_, record) => (
				<Button type='link' className='px-0' onClick={() => navigate(`/ftl-orders/${record.uid}`)}>
					{t(`View`)}
				</Button>
			),
		},
	];

	const _columns = columns.filter((column) => !(mode != 'agent' && column.title === t('User')));

	const handleChange = useMemoizedFn((newQueries: FTLOrdersQuery) => {
		console.log(`[handleChange] newQueries`, newQueries);

		if (Number.isInteger(newQueries.page) && Number.isInteger(newQueries.per_page)) {
			changePagination(newQueries.page, newQueries.per_page);
		} else if (
			Object.keys(newQueries).some((k) =>
				['sort_by', 'sort_value', 'query', 'customer_status', 'last_days'].includes(k),
			)
		) {
			setQueriesWithoutPagination(newQueries);
		}

		changeSearchParams(newQueries);
	});

	const handleChangePagination = useMemoizedFn((page: number, pageSize: number) => {
		handleChange({
			page,
			per_page: pageSize,
		});
	});

	useMount(() => {
		void (async () => {
			await getFTLShipmentList(
				{ pageSize: parsedSearchParams.per_page ?? 20, current: parsedSearchParams.page ?? 1 },
				{
					params: {
						...queriesWithoutPagination,
						customer_status: (queriesWithoutPagination.customer_status ?? []).join(','),
						last_days:
							queriesWithoutPagination.last_days == 'all' ? '' : queriesWithoutPagination.last_days,
					},
				},
			);

			changePagination(parsedSearchParams.page ?? 1, parsedSearchParams.per_page ?? 20);
		})();
	});

	return (
		<div className='h-screen w-full p-[48px] flex flex-col bg-white overflow-hidden'>
			{/* header */}
			<div className='grow-0 shrink-0 w-full h-[44px] flex items-center gap-[8px]'>
				<div className='h3-b text-black'>
					{mode == 'agent' ? t(`FTL Orders`) : t(`My FTL Orders`)}
				</div>
				{FTLShipmentListLoading && <LoadingOutlined />}
			</div>

			<Gap height='36px' />

			{/* filters */}
			<div className='grow-0 shrink-0 w-full h-[42px]'>
				<LTLFTLFilter
					value={{
						query: queriesWithoutPagination.query,
						last_days: queriesWithoutPagination.last_days,
						customer_status: queriesWithoutPagination.customer_status,
					}}
					statusFieldName='customer_status'
					type='order'
					statusOptions={Object.entries(FTLBusinessStatusMap)
						.filter(([value, label]) => value !== '60')
						.map(([value, label]) => ({ label: t(label), value: `${value}` }))}
					enableLastDays={true}
					handleChange={(value) => handleChange(value)}
				/>
			</div>

			<Gap height='24px' />

			{/* table */}
			<div className='flex-auto'>
				<Table
					className='no-cell-border-table'
					rowKey='id'
					columns={_columns}
					dataSource={FTLShipmentList}
					bordered
					pagination={false}
					scroll={{ scrollToFirstRowOnChange: true, x: 'max-content', y: 'calc(100vh - 345px)' }}
				/>
			</div>

			<Gap height='16px' />

			{/* pagination */}
			<Pagination
				className='mx-auto'
				showSizeChanger
				total={total}
				current={current}
				pageSize={pageSize}
				onChange={handleChangePagination}
			/>
		</div>
	);
});

export default FTLOrders;
