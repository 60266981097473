import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';
import CircleBlueFillIcon from '@/icons/circle-blue-fill';
import DestinationIcon from '@/icons/destination-icon';
import { QuoteBusinessStatus } from '@/interfaces/quote.interface';
import { useLTLContext } from '@/services/contexts';
import { observer } from 'mobx-react-lite';
import { Gap } from '@/components/gap';
import { formatMMDDYYYYDate } from '@/utils/format-MMDDYYYY-date';
import {
	ItemTitle,
	ShipmentInfoEditButton,
	ShipmentInfoMap,
	ShipmentInfoPickupDate,
} from '../shipment-info';
import { ShipmentInfoCargoSummary } from '../shipment-info/shipment-info-cargo-summary';
import { ShipmentInfoDestinationAddress } from '../shipment-info/shipment-info-destination-address';
import { ShipmentInfoPickupAddress } from '../shipment-info/shipment-info-pickup-address';

export const SideMap = observer(() => {
	const { t } = useTranslation();

	const ltlContext = useLTLContext();

	return (
		<div className='relative box-border grow-0 overflow-hidden w-[382px] h-fit border-[1px] border-solid border-grey-05'>
			<div className='w-[full] h-[180px]'>
				<ShipmentInfoMap />
			</div>

			<div className=' box-border p-[24px_24px_36px_24px] w-full'>
				<div className='flex flex-col'>
					<div className='w-full justify-between flex items-center gap-[24px]'>
						<ShipmentInfoPickupDate />
						{ltlContext.quote.business_status === QuoteBusinessStatus.SUBMITED && (
							<ShipmentInfoEditButton quoteRateCompleted />
						)}
					</div>

					<Gap height='4px' />

					<div
						style={{
							position: 'absolute',
							left: `35px`,
							top: `245px`,
							height: `114px`,
							border: '1px dashed grey',
						}}
					/>

					<div className='w-full flex gap-[12px]'>
						<div className=' box-border pt-[4px]'>
							<CircleBlueFillIcon className='relative z-1 bg-white' />
						</div>
						<div className='grow flex flex-col'>
							<ShipmentInfoPickupAddress />
							<Divider />
						</div>
					</div>

					<div className='w-full flex gap-[12px]'>
						<div className=' box-border pt-[4px]'>
							<DestinationIcon />
						</div>
						<div className='grow flex flex-col'>
							<ShipmentInfoDestinationAddress />
							<Divider />
						</div>
					</div>

					<ShipmentInfoCargoSummary />

					<Divider />

					<div>
						<div>
							<ItemTitle content={t(`Carrier`)} value={ltlContext.selectedQuoteRate.carrier_name} />
						</div>
						<Gap height='8px' />
						<div
							className=' box-border p-[4px_10px] w-fit border-[1px] border-solid border-grey-01 rounded-[4px] body-4-sb text-grey-01 flex justify-center items-center cursor-pointer'
							onClick={() => {
								ltlContext.step = 2;
							}}
						>
							{t(`Change Carrier`)}
						</div>
						<Gap height='16px' />
						<div className='grid grid-cols-[1fr_1fr_1fr] grid-rows-[auto_auto] gap-x-[12px] gap-y-[16px]'>
							<div>
								<ItemTitle
									content={t(`Estimated Delivery`)}
									value={
										ltlContext.selectedQuoteRate.estimated_delivery_date
											? formatMMDDYYYYDate(ltlContext.selectedQuoteRate.estimated_delivery_date)
											: ''
									}
								/>
							</div>
							<div></div>
							<div>
								<ItemTitle
									content={t(`Transit Time`)}
									value={t(`${ltlContext.selectedQuoteRate?.transit_days ?? '--'} days`)}
								/>
							</div>
							<div>
								<ItemTitle
									content={t(`Base Price`)}
									value={t(`$${ltlContext.selectedQuoteRate.rate}`)}
								/>
							</div>
							<div>
								<ItemTitle
									content={t(`Cargo Value`)}
									value={t(`$${ltlContext.stepThreeFormValues.cargo_value ?? '--'}`)}
								/>
							</div>
							<div>
								<ItemTitle content={t(`Premium`)} value={t(`$${ltlContext.premium}`)} />
							</div>
							{/* <div>
									<ItemTitle content={t(`Extra Insurance`)} value='---' />
								</div> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
});
