import { baseURL } from '..';
import { downloadFactory } from '../download-factory';
import { DownloadPrepayOrderReceiptProps } from './interface';

export const downloadPrepayOrderReceipt = (
	props: DownloadPrepayOrderReceiptProps,
	config?: { preview?: boolean },
) => {
	const { id } = props.path;
	const { fileName } = props.other;
	const url = baseURL + `wallet/${id}/viewReceipt`;

	downloadFactory(url, fileName, { preview: config?.preview });
};
