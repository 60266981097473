import { CSSProperties, FC, memo } from 'react';
import { StarFilled } from '@ant-design/icons';

/** 总星星数，也是满分分值 */
const COUNT = 5;

/** 默认星星颜色 */
const DEFAULT_COLOR = 'rgba(0, 0, 0, 0.06)';
/** 默认星星样式 */
const DEFAULT_STYLE = {
	color: DEFAULT_COLOR,
};

/** 高亮星星颜色 */
const HIGHLIGHT_COLOR = '#fadb14';
/** 高亮星星样式 */
const HIGHLIGHT_STYLE = {
	color: HIGHLIGHT_COLOR,
};

/** 实际分数对应的整数部分对应的星星样式 */
const getIntStyle = (value: number, int: number, index: number): CSSProperties =>
	value >= COUNT || index < int ? HIGHLIGHT_STYLE : DEFAULT_STYLE;

/** 实际分数对应的小数部分对应的星星容器样式 */
const getDecimalWrapperStyle = (decimal: number): CSSProperties => ({
	width: `${decimal * 100}%`,
});

/** 实际分数对应的标签样式 */
const getLabelStyle = (label: string | number): CSSProperties => ({
	color: label === '--' ? DEFAULT_COLOR : '#007bff',
});

export interface RateProps {
	className?: string;
	style?: CSSProperties;
	/** 实际分数 */
	value?: number | string | null;
}

/** LTL 搜索运价步骤二的评分组件，纯静态，5 分满分，支持小数点后两位。*/
export const Rate: FC<RateProps> = memo(({ value, className, style }) => {
	const label = value == null ? '--' : Number(Number(value).toFixed(2));
	const _value = Number(Number(value ?? 0).toFixed(2));
	const int = Math.floor(_value);

	/** 实际分数的小数部分 */
	const decimal = _value - int;

	return (
		<div className={`flex items-center justify-start gap-2 ${className}`} style={style}>
			{Array.from({ length: COUNT }).map((_, index) => (
				<div key={index} className='relative'>
					{/* 占位星星，如果能够完整显示星星，则直接整体显示高亮作为整数部分，否则显示默认，并在上层显示高亮作为小数部分 */}
					<StarFilled style={getIntStyle(_value, int, index)} />

					{/* 高亮小数部分 */}
					{index === int && index < _value && (
						<div
							className='absolute top-0 left-0 overflow-hidden h-full'
							style={getDecimalWrapperStyle(decimal)}
						>
							<StarFilled style={HIGHLIGHT_STYLE} />
						</div>
					)}
				</div>
			))}
			<span className='body-3-sb' style={getLabelStyle(label)}>
				{label}
			</span>
		</div>
	);
});
