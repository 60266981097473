import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { GetItemFreightClassProps, GetItemFreightClassRes } from './interface';

export const getItemFreightClass = async (props: GetItemFreightClassProps) => {
	return await axios.get<GetItemFreightClassRes>('/tool/freight/class/calculate', {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
		params: props.params,
	});
};
