export const replaceChineseSymbol = (value: string) => {
	return value
		.replaceAll('“', '"')
		.replaceAll('”', '"')
		.replaceAll('，', ',')
		.replaceAll('。', '.')
		.replaceAll('：', ':')
		.replaceAll('；', ';')
		.replaceAll('？', '?')
		.replaceAll('！', '!')
		.replaceAll('（', '(')
		.replaceAll('）', ')')
		.replaceAll('【', '[')
		.replaceAll('】', ']')
		.replaceAll('；', ';')
		.replaceAll('　', ' ');
};
