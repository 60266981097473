import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { PickupDateFromNowProps, PickupDateFromNowRes } from './interface';

export const getPickupDateMap = async (props: PickupDateFromNowProps) => {
	return await axios.get<PickupDateFromNowRes>(`/tools/pickup-date-from-now`, {
		params: props.params,
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
